export const dashboardGroups = [
	{
		name: 'Administration',
		links: [
			{
				title: 'Overview',
				icon: 'fa-regular fa-calendar-days',
				href: '/admin/calendar'
			},
			{
				title: 'Tomorrow',
				icon: 'fa fa-calendar-alt',
				href: '/admin/schedule/tomorrow'
			},
			{
				title: 'Today',
				icon: 'fa fa-calendar-alt',
				href: '/admin/schedule/today'
			},
			{
				title: 'Vouchers',
				icon: 'fas fa-barcode',
				href: '/admin/vouchers'
			},
			{
				title: 'Discounts',
				icon: 'fas fa-tags',
				href: '/admin/discounts'
			},
			// {
			// 	title: 'Emailer',
			// 	icon: 'fa fa-at'
			// 	// href: '/admin/emailer'
			// },
			{
				title: 'Thermal Chasers',
				icon: 'fa fa-plane-departure'
				// href: '/admin/thermal-chasers'
			},
			{
				title: 'Pilots',
				icon: 'fa fa-users',
				href: '/admin/pilots'
			},
			{
				title: 'Partners',
				icon: 'fa-solid fa-people-group',
				href: '/admin/partners'
			},
			{
				title: 'Memberships',
				icon: 'fa-regular fa-id-badge',
				href: '/admin/memberships'
			},
			{
				title: 'Workshop',
				icon: 'fas fa-wrench',
				href: '/admin/workshop'
			},
			{
				title: 'Workshop Availability',
				icon: 'fa fa-calendar-alt',
				href: '/admin/workshop-calendar'
			},
			{
				title: 'Activities',
				icon: 'fa fa-list',
				href: '/admin/activities'
			},
			// {
			// 	title: 'Historic',
			// 	icon: 'fa fa-list',
			// 	href: '/admin/historic'
			// },
			{
				title: 'See-History',
				icon: 'fa fa-list'
				// href: '/admin/see-history'
			},
			{
				title: 'Payments',
				icon: 'fa fa-list'
				// href: '/admin/payments'
			},
			{
				title: 'Booking Limits',
				icon: 'fa fa-list'
				// href: '/admin/booking-limits'
			},
			{
				title: 'Report Credits',
				icon: 'fa fa-list'
				// href: '/admin/report-credits'
			},
			{
				title: 'Tutorial',
				icon: 'fa fa-list'
				// href: '/admin/tutorial'
			},
			// {
			// 	title: 'Email Template Editor',
			// 	icon: 'fa fa-remove-format',
			// 	href: '/admin/email-template'
			// },
			{
				title: 'Emails',
				icon: 'fa fa-envelope',
				href: '/admin/emails'
			},
			{
				title: 'Stripe Payments',
				icon: 'fab fa-stripe',
				href: '/admin/payments'
			}
		]
	},
	{
		name: 'Staff',
		links: [
			{ title: 'Availability', icon: 'fa-solid fa-calendar-week', href: '' },
			{ title: 'Invoices', icon: 'fa-solid fa-file-invoice-dollar', href: '/admin/invoices' }
		]
	}
];
