import { useState } from 'react';
import { useApp } from 'src/components/App/appContext';
import BookParticipationModal from 'src/components/BookParticipationModal';
import ProfileSetupModal from '../ProfileSetupModal';
export default function SetupAndBookModal({ ...props }) {
	const [open, setOpen] = useState(false);
	const { user } = useApp();

	return user.profileComplete ? (
		<BookParticipationModal open={open} setOpen={setOpen} {...props} />
	) : (
		<ProfileSetupModal open={open} setOpen={setOpen} {...props} />
	);
}
