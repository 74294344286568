import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import moment from 'moment';
import { useApp } from 'src/components/App/appContext';
import { AskConfirm } from 'src/components/AskComponents';
import graphql from 'src/utils/graphql';
import LoadingButton from 'src/components/LoadingButton';
export default function BookingDetailsModal(props) {
	const { user } = useApp();
	const { booking, refresh, children } = props;
	const [loading, setLoading] = useState(false);

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const cancelBooking = async () => {
		setLoading(true);
		await graphql({
			query: `
			mutation($id: ID!) {
				unbookParticipation(participationId: $id)
			}
		`,
			variables: {
				id: booking.id
			}
		});
		await refresh();
		setLoading(false);
		setModal(false);
	};

	return (
		<div>
			<span onClick={() => setModal(true)}>{children || 'View Booking'}</span>
			{console.log(booking)}
			<Modal size="lg" isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>
					{booking.activity.name} <small>{booking.date && new Date(booking.date).toLocaleDateString('en-gb')}</small>
				</ModalHeader>
				<ModalBody>
					<div className="row">
						<div className="col-6">
							{booking.canPilotCancel && (
								<>
									<p>{booking.activity.description}</p>
									<p>
										You are booked in for <strong>{booking.activity.name}</strong> on{' '}
										<strong>{new Date(booking.date).toLocaleDateString('en-gb')}</strong>
									</p>

									{user.profileComplete ? (
										<p>
											You will be notified via SMS on <strong>{user.mobile}</strong> the evening before with an update
											on timings
										</p>
									) : (
										<Alert color="warning">
											You need to complete your profile before you can be notified via SMS the evening before
										</Alert>
									)}
								</>
							)}

							{!booking.canPilotCancel && booking.date && (
								<Alert color="warning">
									You cannot cancel this booking yourself as either the date has passed, or it's within 24 hours of the
									booked date
								</Alert>
							)}

							{booking.expired && !booking.date && (
								<Alert color="warning">
									Your booking has expired as you did not book a date within a year of purchasing
								</Alert>
							)}

							{booking.canPilotCancel && (
								<AskConfirm
									title="Are you sure you want to cancel this booking?"
									description={
										'This will cancel your booking for ' +
										booking.activity.name +
										' on ' +
										moment(booking.date).format('DD/MM/YYYY')
									}
									onSubmit={cancelBooking}
								>
									<LoadingButton loading={loading} color="danger" style={{ width: '100%' }}>
										Cancel Booking
									</LoadingButton>
								</AskConfirm>
							)}
						</div>
						<div className="col-6">
							<img className="img-fluid" src={booking.activity.product.imageObject.url} alt={booking.activity.name} />
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}
