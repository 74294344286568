import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClientLink from 'src/components/ClientLink';
import Spinner from 'src/components/Spinner';
import graphql from 'src/utils/graphql';
import styled from 'styled-components';
import BookingDetailsModal from './components/BookingDetailsModal';
import SetupAndBookModal from './components/SetupAndBookModal';
import query from './query';
import { useApp } from 'src/components/App/appContext';
import { Alert, Button } from 'reactstrap';

export default function Bookings() {
	const { user } = useApp();
	const navigator = useNavigate();
	const [loading, setLoading] = useState(true);
	const [fetched, setFetched] = useState(false);

	const [unbooked, setUnbooked] = useState([]);
	const [booked, setBooked] = useState([]);
	const [historic, setHistoric] = useState([]);

	const getBookings = useCallback(async () => {
		try {
			setLoading(true);
			const { me } = await graphql({ query }, true);
			const today = new Date();
			today.setHours(0, 0, 0, 0);

			const unbookedParticipations = [];
			const bookedParticipations = [];
			const historicParticipations = [];

			me[0].participations.forEach((participation) => {
				if (!participation.date && !participation.expired) {
					unbookedParticipations.push(participation);
				} else {
					const participationDate = new Date(participation.date);
					if (participationDate >= today) {
						bookedParticipations.push(participation);
					} else {
						historicParticipations.push(participation);
					}
				}
			});

			setUnbooked(unbookedParticipations);
			setBooked(bookedParticipations);
			setHistoric(historicParticipations);
		} catch (error) {
			console.log(error);
		} finally {
			setFetched(true);
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		if (!user) return navigator('/');
		if (!fetched) getBookings();
	}, [getBookings, user, navigator, fetched]);

	const CancelBadge = styled.div`
		font-size: 1em;
		color: darkred;
		background: rgba(255, 255, 255, 0.5);
		border: 2px double darkred;
		border-radius: 4px;
		padding: 0px 10px;
		position: relative;
		display: inline-block;
		margin-top: 4px;
		margin-bottom: -4px;
	`;

	const ExpiredBadge = styled.div`
		font-size: 1em;
		color: darkred;
		background: rgba(255, 255, 255, 0.5);
		border: 2px double darkred;
		border-radius: 4px;
		padding: 0px 10px;
		position: relative;
		display: inline-block;
		margin-top: 4px;
		margin-bottom: -4px;
	`;

	if (!user) return null;
	if (loading) return <Spinner />;
	const bookingCount = unbooked.length + booked.length + historic.length;
	if (!bookingCount)
		return (
			<div className="my-5" style={{ textAlign: 'center' }}>
				<h3>You currently have no bookings</h3>
				<Button color="primary" onClick={() => navigator('/choose-sport')}>
					Click here to browse activities
				</Button>
			</div>
		);
	return (
		<div>
			{unbooked.length > 0 && (
				<>
					<h2>Unbooked Days</h2>
					{unbooked
						.reduce((groups, b) => {
							const groupIndex = groups.findIndex((group) => group[0].activity.id === b.activity.id);

							if (groupIndex >= 0) {
								groups[groupIndex].push(b);
							} else {
								groups.push([b]);
							}
							return groups;
						}, [])
						.map((group) => {
							const b = group[0];
							return (
								<SetupAndBookModal activityId={b.activity.id} participationId={b.id} onSubmit={getBookings}>
									<ClientLink
										title={b.activity.name}
										icon="fas fa-calendar-plus"
										text={
											group.length > 1 ? (
												<>
													You have <b>{group.length} days</b> available, click to book
												</>
											) : (
												<>
													You have <b>1 day</b> available, click to book
												</>
											)
										}
									/>
								</SetupAndBookModal>
							);
						})}
				</>
			)}

			{booked.length > 0 && (
				<>
					<hr />
					<h2>Your Bookings</h2>
					{booked.map((b) => (
						<BookingDetailsModal refresh={getBookings} booking={b}>
							<ClientLink
								title={b.activity.name}
								icon="fas fa-calendar-check"
								onClick={(e) => e.stopPropagation()}
								text={
									<>
										You{b.status.id > 1 ? ' were' : ' are'} booked in for{' '}
										<b>{new Date(b.date).toLocaleDateString('en-GB')}</b> <br />
										{b.status.id > 1 ? <CancelBadge>{b.status.name}</CancelBadge> : <>Click here to cancel this date</>}
									</>
								}
							/>
						</BookingDetailsModal>
					))}
				</>
			)}

			{historic.length > 0 && (
				<>
					<hr />
					<h2>Booking History</h2>
					{historic.map((b) => (
						<BookingDetailsModal refresh={getBookings} booking={b}>
							<ClientLink
								title={b.activity.name}
								icon="fas fa-calendar"
								text={
									<>
										{b.date && (
											<>
												Was booked for <b>{new Date(b.date).toLocaleDateString('en-GB')}</b>
												<br />
											</>
										)}
										{b.status.id > 1 && <CancelBadge>{b.status.name}</CancelBadge>}
										{b.expired && !b.date && <ExpiredBadge>This booking has expired</ExpiredBadge>}
									</>
								}
							/>
						</BookingDetailsModal>
					))}
				</>
			)}
		</div>
	);
}
