import { useState, useEffect, useCallback } from 'react';
import axios from '../../../utils/axios';
import { Table, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import AskText from 'src/components/AskComponents/AskText';

import { useApp } from 'src/components/App/appContext';
export default function Content() {
	const { get, post } = useApp();

	const [data, setData] = useState([]);

	// Initially load data
	const loadData = useCallback(async () => {
		var { data } = await get('/content/workshop');
		setData(data);
	}, []);
	useEffect(() => {
		loadData();
	}, [loadData]);

	const update = async (id, text) => {
		try {
			await post('/content/service/update', { id, text });
			loadData();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<h3>Workshop Services</h3>
			<Table>
				<thead>
					<tr>
						<th>Service Name</th>
						<th>Service Text</th>
					</tr>
				</thead>
				<tbody>
					{data.map((service, index) => {
						const { service_id, service_name, service_text } = service;

						return (
							<tr>
								<td>{service_name}</td>
								<td>
									<AskText
										description="Change text"
										type="text"
										value={service_text}
										onSubmit={() => update(service_id, service_text)}
									>
										<span className={`text-inverse `}>
											{service_text}
											<i className="fa fa-fw fa-pencil" />
										</span>
									</AskText>
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</>
	);
}
