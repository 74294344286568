import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Row, FormGroup, Input, Label } from 'reactstrap';
import { useApp } from 'src/components/App/appContext';
import { AskConfirm } from 'src/components/AskComponents';
import Spinner from 'src/components/Spinner';
import graphql from 'src/utils/graphql';
import CreateInvoiceModal from './CreateInvoiceModal';
import MarkInvoicePaidModal from './MarkInvoicePaidModal';

export default function Invoices() {
	const { user } = useApp();
	const [showPaid, setShowPaid] = useState(false);
	let invoiceAdmin = user && user.permits.includes('INVOICE_CLIENT_ADMIN');

	const { data, isLoading, error, refetch } = useQuery(['invoices', user], async () => {
		if (!user) return;

		const myOwn = invoiceAdmin ? '' : `,personId:{_eq:${user.id}}`;

		const { personInvoice } = await graphql(
			{
				query: `
				{
					personInvoice(where: {deleted: {_eq: false} ${myOwn} }) {
						id
						person {
							name
						}
						uploadedAt
						notes
						key
						payeeName
						value
						filename
						bankDetails
						payeeName
						payments {
							paidAt
							person{
								name
							}
						}
					}
				}
				`
			},
			true
		);
		return { personInvoice };
	});

	const deleteInvoice = async (id) => {
		await graphql(
			{
				query: `
				mutation ($id:bigint!){
					updatePersonInvoiceByPk(pkColumns:{id:$id},_set:{deleted:true}){
						id
					}
				}`,
				variables: { id }
			},
			true
		);
		await refetch();
	};

	if (isLoading || !user) return <Spinner />;
	return (
		<Row>
			<div style={{ alignItems: 'center' }} className="d-flex justify-content-end mb-3 w-100">
				<FormGroup switch>
					<Input
						type="switch"
						checked={showPaid}
						onClick={() => {
							setShowPaid(!showPaid);
						}}
					/>
					<Label check>Show Paid Invoices</Label>
				</FormGroup>
				&nbsp;&nbsp;&nbsp;
				<CreateInvoiceModal>
					<Button color="primary">
						<i className="fas fa-plus fa-fw" />
						Create Invoice
					</Button>
				</CreateInvoiceModal>
			</div>

			{data.personInvoice
				.sort((a, b) => {
					if (a.payments.length === 0 && b.payments.length > 0) return -1;
					if (a.payments.length > 0 && b.payments.length === 0) return 1;
					return 0;
				})
				.filter((i) => (showPaid ? i.payments.length !== 0 : i.payments.length === 0))
				.map((i) => (
					<Col md={6} sm={12}>
						<Card>
							<CardHeader>
								<div className="d-flex justify-content-between">
									<h4>{i.payeeName}</h4>
									<div className="d-flex align-items-center">
										{i.payments.length > 0 ? (
											<Badge color="success" className="mr-2">
												Paid by {i.payments[0].person.name} on{' '}
												{new Date(i.payments[0].paidAt).toLocaleDateString('en-GB')}
											</Badge>
										) : (
											<Badge color="danger" className="mr-2">
												Unpaid
											</Badge>
										)}
									</div>
								</div>
							</CardHeader>
							<CardBody style={{ paddingBottom: 0 }}>
								<div style={{ position: 'relative' }}>
									<b>Notes:</b>
									<pre>{i.notes}</pre>

									<b>Bank Details</b>
									<pre>{i.bankDetails}</pre>

									<b>Invoice File</b>
									<p>
										<a
											href={`https://green-dragons.s3.eu-west-2.amazonaws.com/uploads/${i.key}`}
											target="_blank"
											rel="noreferrer"
										>
											{i.filename}
										</a>
									</p>

									<br />
									<br />
									<div style={{ position: 'absolute', bottom: 0, marginBottom: 5 }}>
										<small>
											Created by {i.person.name} at {new Date(i.uploadedAt).toLocaleDateString('en-GB')}
										</small>
									</div>
								</div>
							</CardBody>
							{!i.payments.length && (
								<CardFooter className="d-flex justify-content-end">
									<div style={{ position: 'absolute', bottom: 5, left: 10 }}>
										<h4>£{i.value}</h4>
									</div>
									<AskConfirm
										title="Delete Invoice"
										description="Are you sure you want to delete this invoice?"
										onSubmit={() => deleteInvoice(i.id)}
									>
										<Button color="danger">
											<i className="far fa-times-circle fa-fw" />
											&nbsp;Delete
										</Button>
									</AskConfirm>
									&nbsp;
									{invoiceAdmin && (
										<MarkInvoicePaidModal id={i.id}>
											<Button color="primary">
												<i className="far fa-check-circle fa-fw" />
												&nbsp;Mark Paid
											</Button>
										</MarkInvoicePaidModal>
									)}
								</CardFooter>
							)}
						</Card>
					</Col>
				))}
		</Row>
	);
}
