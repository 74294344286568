import React, { useRef } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	FormGroup,
	Input,
	Label,
	UncontrolledTooltip,
	FormFeedback
} from 'reactstrap';

// import EditIcon from "@mui/icons-material/Edit";

export default function AskText(props) {
	const {
		title = 'Edit',
		description = '',
		placeholder = '',
		label = '',
		type,
		submitLabel = 'Save',
		onSubmit = () => {},
		value = '',
		id = ''
	} = props;
	const [open, setOpen] = React.useState(false);
	const [inputValue, setInputValue] = React.useState('');
	const [saving, setSaving] = React.useState(false);
	const [error, setError] = React.useState(false);
	const inputRef = useRef();

	const openDialog = () => {
		setInputValue(value);
		setError(false);
		setSaving(false);
		setOpen(true);

		setTimeout(() => {
			inputRef.current?.select();
		}, 200);
	};

	const closeDialog = () => {
		if (saving) return;
		setError(false);
		setSaving(false);
		setOpen(false);
	};

	const handleSubmit = async () => {
		if (saving) return;
		setSaving(true);
		setError(false);
		let res = null;
		// if (inputValue.trim() !== value)
		res = await onSubmit(inputValue.trim());
		setSaving(false);
		if (!res) return setOpen(false);
		setError(res);
	};

	const openButton = props.children ? (
		<span onClick={openDialog} style={{ cursor: 'pointer' }} id={`askTextTooltip-${id}`}>
			{props.children}
		</span>
	) : (
		<i
			id={`askTextTooltip-${id}`}
			style={{ cursor: 'pointer' }}
			onClick={openDialog}
			className="fa fa-pencil-alt ml-2"
		/>
	);

	return (
		<>
			&nbsp;
			{openButton}
			{!props.children && (
				<UncontrolledTooltip placement="right" target={`askTextTooltip-${id}`} trigger="hover">
					Click to edit
				</UncontrolledTooltip>
			)}
			<Modal fullWidth isOpen={open} toggle={closeDialog} aria-labelledby="form-dialog-title">
				<ModalHeader id="form-dialog-title">{title}</ModalHeader>
				<ModalBody>
					{description && <small>{description}</small>}

					<FormGroup>
						{label && <Label for="exampleEmail">{label}</Label>}
						<Input
							innerRef={inputRef}
							type={type || 'textarea'}
							value={inputValue}
							name="text"
							onChange={(e) => {
								setInputValue(e.target.value);
								setError(false);
							}}
							invalid={!!error}
							placeholder={placeholder}
							onKeyDown={(e) => {
								// if (e.ctrlKey && e.key === 'Enter')
								if (e.key === 'Enter') handleSubmit();
							}}
						/>
						{error && <FormFeedback style={{ fontSize: '1em' }}>{error}</FormFeedback>}
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button disabled={saving} onClick={closeDialog} color="secondary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary">
						{saving ? <i className="mx-auto fa fa-cog fa-spin ml-2" /> : submitLabel}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
