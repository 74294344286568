import { BrowserRouter, HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Container } from 'reactstrap';

import { useApp } from 'src/components/App/appContext';
import Footer from './components/Footer';
import Topbar from './components/Topbar/Navbar';

import AdminDashboard from 'src/views/admin/Dashboard';

import ForgotPassword from 'src/views/ForgotPassword';
import Login from 'src/views/Login';

import Activities from 'src/views/admin/Activities';
import Pilot from 'src/views/admin/Pilot';
import Pilots from 'src/views/admin/Pilots';

import Calendar from 'src/views/admin/Calendar';
import Discounts from 'src/views/admin/Discounts';
import EmailTemplate from 'src/views/admin/EmailTemplate';
import EmailViewer from 'src/views/admin/EmailViewer';
import Emails from 'src/views/admin/Emails';
import AdminThermalChasers from 'src/views/admin/ThermalChasers';
import Unlayer from 'src/views/admin/Unlayer';
import Vouchers from 'src/views/admin/Vouchers';
import Workshop from 'src/views/admin/Workshop';
import WorkshopCalendar from 'src/views/admin/WorkshopCalendar';
import WorkshopBooking from 'src/views/admin/Workshop/WorkshopBooking';
import Invoices from './views/admin/Invoices';
import Payments from './views/admin/Payments';
import Memberships from './views/admin/Memberships';

import ThermalChasers from 'src/views/ThermalChasers';
import Bookings from 'src/views/client/Bookings';
import ChooseSport from 'src/views/client/ChooseSport';
import Enroll from 'src/views/client/Enroll';
import Homepage from 'src/views/client/Homepage';
import Membership from 'src/views/client/Membership';
import Profile from './views/client/Porfile';

import Cart from 'src/views/client/Cart';
import CartThankYou from './views/client/Cart/CartThankYou';

import Content from 'src/views/admin/Content';
import Historic from 'src/views/admin/Historic';
import Partners from 'src/views/admin/Partners';

import { AppProvider } from 'src/components/App/appContext';
import { CartProvider } from './contexts/CartContext';

import Servicing from 'src/views/client/Servicing';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Today from './views/admin/Today';
import ServiceBooking from 'src/views/client/Servicing/ServiceBooking';

function RootApp() {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<AppProvider>
				<CartProvider>
					<App />
				</CartProvider>
			</AppProvider>
		</QueryClientProvider>
	);
}

function App() {
	const isHeadless = window?.websiteEmbed;
	console.log('Headless', isHeadless);

	const Router = isHeadless ? HashRouter : BrowserRouter;
	return (
		<Router>
			<RoutesContent />
		</Router>
	);
}
function RoutesContent() {
	return (
		<>
			<Topbar />
			<Container>
				<Routes>
					<Route path="/calendar/:activity_id" element={<Calendar />} />
					<Route path="/" element={<Dashboard />} />
					<Route path="/cart/:productId/:gift" element={<Cart />} />
					<Route path="/cart/:productId" element={<Cart />} />
					<Route path="/cart" element={<Cart />} />
					<Route path="/cart/thankyou" element={<CartThankYou />} />
					<Route path="/homepage" element={<Homepage />} />
					<Route path="/bookings" element={<Bookings />} />
					<Route path="/choose-sport" element={<ChooseSport />} />
					<Route path="/enrol/:id" element={<Enroll />} exact />
					{/* Go to enroll and open a Product Modal for the activity */}
					<Route path="/enrol/:id/:activityId" element={<Enroll />} exact />
					<Route path="/servicing" element={<Servicing />} />
					<Route path="/servicing/:id" element={<ServiceBooking />} />
					<Route path="/membership" element={<Membership />} />
					<Route path="/profile" element={<Profile />} />
					<Route path="/activity" element={<Calendar />} />
					<Route path="/thermal-chasers" element={<ThermalChasers />} />
					<Route path="/admin/calendar" element={<Calendar />} />
					<Route path="/admin/schedule/:date" element={<Today />} />
					<Route path="/admin/schedule/" element={<Today />} />
					<Route path="/admin/vouchers" element={<Vouchers />} />
					<Route path="/admin/discounts" element={<Discounts />} />
					<Route path="/admin/content" element={<Content />} />
					<Route path="/admin/historic" element={<Historic />} />
					<Route path="/admin/activities" element={<Activities />} />
					<Route path="/admin/pilots" element={<Pilots />} />
					<Route path="/admin/pilot/:id" element={<Pilot />} />
					<Route path="/admin/partners" element={<Partners />} />
					<Route path="/admin/email-template" element={<EmailTemplate />} />
					<Route path="/admin/unlayer" element={<Unlayer />} />
					<Route path="/admin/unlayer/:id" element={<Unlayer />} />
					<Route path="/admin/emails" element={<Emails />} />
					<Route path="/admin/emails/:id" element={<EmailViewer />} />
					<Route path="/admin/memberships" element={<Memberships />} />
					<Route path="/admin/workshop" element={<Workshop />} />
					<Route path="/admin/workshop-calendar" element={<WorkshopCalendar />} />
					<Route path="/admin/workshop/:id" element={<WorkshopBooking />} />
					<Route path="/admin/thermal-chasers" element={<AdminThermalChasers />} />
					<Route path="/admin/invoices" element={<Invoices />} />
					<Route path="/admin/payments" element={<Payments />} />
					<Route path="/forgot-password/:token" element={<ForgotPassword />} exact />
					<Route path="/login" element={<Login />} exact />
					<Route path="/auth/:token" element={<Login />} exact />
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</Container>
			<Footer />
		</>
	);
}

const Dashboard = () => {
	const { user } = useApp();

	return user?.admin || user?.grantedPermits?.find((p) => p.permit.name === 'STAFF') ? (
		<AdminDashboard />
	) : (
		<Homepage />
	);
};

export default RootApp;
