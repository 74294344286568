import React from 'react';
import { useQuery } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import Spinner from 'src/components/Spinner';
import { Col, Row, ListGroup, ListGroupItem, FormGroup, Input, Label, UncontrolledCollapse, Button } from 'reactstrap';
import useMediaQuery from 'src/utils/useMediaQuery';

export default function Partners() {
	const isMobile = useMediaQuery('(max-width: 599px)');
	const [search, setSearch] = React.useState('');
	const { data, isLoading, refetch } = useQuery(['partners'], async () => {
		const data = await graphql(
			{
				query: `
					{
						partner(orderBy:{id:ASC}) {
							id
							name
							email
							tel
							facilities {
								facility {
									id
									name
								}
								unsure
							}
						}
						facility(orderBy:{id:ASC}) {
							id
							name
						}
					}`
			},
			true
		);
		return { ...data };
	});

	if (isLoading) return <Spinner />;

	return (
		<>
			<Row>
				<Col md={6} sm={12}>
					<h3 className="">Partners</h3>
					<Input
						className="mb-2"
						type="text"
						id="description"
						placeholder="Search ..."
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
					<Button size="sm" id="toggle-partners" color="primary" className="mb-2" block outline>
						Toggle Partners
					</Button>
					<hr style={{ marginBottom: 0 }} />
					<UncontrolledCollapse toggler="#toggle-partners" defaultOpen={!isMobile}>
						<ListGroup flush>
							<ListGroupItem>
								<Row>
									<Col>
										<h6 className="mb-0">Partner</h6>
									</Col>
									<Col>
										<h6 className="mb-0">Facilities</h6>
									</Col>
								</Row>
							</ListGroupItem>
							{data.partner
								.filter((p) => {
									if (!search) return true;
									return p.name.toLowerCase().includes(search.toLowerCase());
								})
								.map((p, i) => {
									return (
										<ListGroupItem key={i}>
											<Row>
												<Col>
													<p className="mb-0">{p.name}</p>
												</Col>
												<Col>
													{p.facilities.map((f, i) => {
														return (
															<p key={i} className="mb-0">
																{f.facility.name}{' '}
																{f.unsure ? (
																	<i className="fa-regular fa-circle-check" />
																) : (
																	<i className="fa-regular fa-circle-question" />
																)}
															</p>
														);
													})}
												</Col>
											</Row>
										</ListGroupItem>
									);
								})}
						</ListGroup>
					</UncontrolledCollapse>
				</Col>
				<Col md={6} sm={12}>
					<h3 className="">Facilities</h3>

					<Button size="sm" id="toggle-facilities" color="primary" className="mb-2" block outline>
						Toggle Facilities
					</Button>
					<hr style={{ marginBottom: 0 }} />
					<UncontrolledCollapse toggler="#toggle-facilities" defaultOpen={!isMobile}>
						<ListGroup flush>
							{data.facility.map((f, i) => {
								return <ListGroupItem key={i}>{f.name}</ListGroupItem>;
							})}
						</ListGroup>
					</UncontrolledCollapse>
				</Col>
			</Row>
		</>
	);
}
