import React, { useState, useCallback, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Table, Button } from 'reactstrap';

export default function RegistrationsModel(props) {
	const { registrations, children } = props;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	return (
		<>
			<Button color="info" onClick={toggle} size="sm">
				{children}
			</Button>
			<Modal size="lg" isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Registrations</ModalHeader>
				<ModalBody>
					<Table striped bordered responsive>
						<tr>
							<td>Name</td>
							<td>Email</td>
							<td>Mobile</td>
						</tr>
						{registrations &&
							registrations.map(({ person }) => {
								return (
									<tr>
										<td>{person.name}</td>
										<td>{person.email}</td>
										<td>{person.mobile}</td>
									</tr>
								);
							})}
					</Table>
				</ModalBody>
			</Modal>
		</>
	);
}
