import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import Spinner from 'src/components/Spinner';
import graphql from 'src/utils/graphql';
import { useNavigate } from 'react-router-dom';
export default function SendModal({ children, html, subject, to, generateHTML, emailId, text }) {
	const navigate = useNavigate();
	const [modal, setModal] = useState(false);
	const [sending, setSending] = useState(false);
	const [sentCount, setSentCount] = useState(0);
	const [error, setError] = useState(null);
	const [sesEmailId, setSesEmailId] = useState(null);

	const spinner = !html || sending;

	const toggle = () => setModal(!modal);

	const open = () => {
		generateHTML();
		setModal(true);
		setSentCount(0);
		setError(null);
	};

	const flushOutbox = async (sesEmailId) => {
		try {
			let moreEmails = true;
			while (moreEmails) {
				const res = await graphql({
					query: `
					mutation($sesEmailId: ID!) {
						flushOutbox(sesEmailId: $sesEmailId)
					}
				`,
					variables: {
						sesEmailId
					}
				});
				setSentCount((prev) => prev + res.flushOutbox);
				moreEmails = res.flushOutbox;
			}
		} catch (error) {
			setError(error);
		}
	};

	const send = async () => {
		if (!html) return;

		try {
			setError(null);
			setSending(true);
			setSesEmailId(null);
			const { sendEmailToOutbox: sesEmailId } = await graphql({
				query: `
				mutation($html: String!, $subject: String!, $to: [EmailToInput]!, $emailId: ID!, $text: String!)  {
					sendEmailToOutbox(html: $html, subject: $subject, to: $to, emailId: $emailId, text: $text)
				}
				`,
				variables: {
					html,
					text,
					subject,
					to,
					emailId
				}
			});
			setSesEmailId(sesEmailId);
			await flushOutbox(sesEmailId);
		} catch (error) {
			setError(error);
		} finally {
			setSending(false);
		}
	};

	return (
		<div>
			<span onClick={open}>{children || 'Open'}</span>

			<Modal size="md" isOpen={modal}>
				<ModalHeader>Email Sender</ModalHeader>
				<ModalBody>
					<p>Are you sure you want to send {to.length} emails?</p>
					{spinner && <Spinner noTransform>Sending Emails...</Spinner>}
					{error && <Alert color="danger">{error.message}</Alert>}
					{!spinner && sentCount > 0 && <Alert color="success">All emails sent! You can close this dialog now.</Alert>}
					{sentCount > 0 && (
						<div style={{ textAlign: 'center' }}>
							<h4>{sentCount} emails sent</h4>
							<Button color="primary" onClick={() => navigate(`/admin/emails/${sesEmailId}`)}>
								View Sent Email
							</Button>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<Button disabled={spinner} color="secondary" onClick={toggle}>
						Close
					</Button>
					<Button disabled={spinner || (!spinner && sentCount)} color="primary" onClick={send}>
						Send {to.length} Emails
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}
