import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import axios from 'src/utils/axios';
import {
	Card,
	Row,
	Col,
	Table,
	Button,
	Input,
	Label,
	CardHeader,
	UncontrolledTooltip,
	ListGroup,
	ListGroupItem,
	CardBody
} from 'reactstrap';
import moment from 'moment';
import ParticipationModal from './ParticipationModal';
import Spinner from 'src/components/Spinner';

import { AskConfirm, AskDate, AskSelect, AskText } from 'src/components/AskComponents';
import { useQuery, useMutation } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import AddParticipationModal from './AddParticipationModal';
import { useApp } from 'src/components/App/appContext';
import AddWorkshopBookingModal from './AddWorkshopBookingModal';
import { Tab } from 'bootstrap';
import AddCourseCompletion from './AddCourseCompletion';
import AddDiscountCode from './AddDiscountCode';

const query = `#graphql
query ($id: bigint!) {
  personByPk(id: $id) {
    id
    admin
    dob
    firstName
	lastName
    gender
	emailMarketing
    avatarObject {
      url
    }
    weight
    height
    email
    mobile
    createdAt
    profileComplete
	staffNotes
	bhpaNumber
	bhpaExpiry
	grantedPermits {
		id
		permit {
			id
			name
		}
	}
	personDiscountCodes {
		id
		discountCode {
			id
			expiry
			name
		}
	}
	participations {
      id
      date
      status {
        name
      }
      activity {
        id
        name
      }
	  expiresAt
    }
	completedCourses {
		id
		activity {
			name
		}
		date
		notes
	}
	workshopBookings {
		id
		date
		workshopBookingService {
			product {
				name
			}
		}
		status {
			name
		}
	}
	 currentMembership {
		id
      endDate
      type {
        name
      }
    }
	memberships(where: { endDate: { _lte: "${moment().utc().format('YYYY-MM-DDTHH:mm:ssZ')}" } }) {
      id
      type {
        name
      }
      endDate
    }
  }
  clubMembershipType {
    id
    product {
      id
      name
      description
      visible
      price
    }
  }
  permit {
	id
	name
  }
  people:person(orderBy: {name:ASC}) {
	id
	name
	email
  }
}
`;
export default function Pilot() {
	let history = useNavigate();
	const { id } = useParams();
	const { user } = useApp();

	const { data, isLoading, error, refetch } = useQuery(['person', id], async () => {
		const { personByPk, clubMembershipType, permit, people } = await graphql(
			{
				query,
				variables: { id }
			},
			true
		);
		return { ...personByPk, clubMembershipType, permit, people };
	});

	const updatePerson = async (values) => {
		try {
			await graphql({
				query: `
				mutation ($input: PersonInput!) {
					savePerson(input: $input)
				}
				`,
				variables: {
					input: {
						id,
						...values
					}
				}
			});
			await refetch();
		} catch (error) {
			alert(error.message);
		}
	};

	const moveBookings = async (personId) => {
		try {
			await graphql(
				{
					query: `#graphql
				mutation ($personId:bigint!,$ids:[bigint!]!) {
					updateParticipation(where: {id: {_in: $ids}}, _set: {personId: $personId}) {
						affectedRows
					}
				}
				`,
					variables: {
						personId,
						ids: selectedBookings
					}
				},
				true
			);
			await refetch();
			setSelectedBookings([]);
		} catch (error) {
			alert(error.message);
		}
	};

	const [person, setPerson] = useState(null);
	const [people, setPeople] = useState([]);
	const [participations, setParticipations] = useState([]);
	const [flyingSites, setFlyingSites] = useState([]);
	const [emailTemplates, setEmailTemplates] = useState([]);
	const [emailTemplate, setEmailTemplate] = useState([]);
	const [noteValue, setNoteValue] = useState('');
	const [selectedPermit, setSelectedPermit] = useState(null);
	const [selectedBookings, setSelectedBookings] = useState([]);

	const toggleSelectedBooking = (id) => {
		if (selectedBookings.includes(id)) {
			setSelectedBookings(selectedBookings.filter((b) => b !== id));
		} else {
			setSelectedBookings([...selectedBookings, id]);
		}
	};

	useEffect(() => {
		if (!data) return;
		setPerson(data.person);
	}, [data]);

	const emailPilot = () => {
		if (emailTemplate) {
			history(`/admin/emailer/${emailTemplate}/${id}`);
		}
	};

	const addStaffNote = async () => {
		await graphql(
			{
				query: `
			mutation ($id:bigint!,$input:PersonSetInput!){
				updatePersonByPk(pkColumns:{id:$id},_set:$input) {
					id
				}
			}`,
				variables: {
					id,
					input: {
						staffNotes: data.staffNotes
							? `${data.staffNotes}<p><b>${moment().format('DD/MM/YYYY')} - ${user.name}:</b> ${noteValue}</p>`
							: `<p><b>${moment().format('DD/MM/YYYY')} - ${user.name}:</b> ${noteValue}</p>`
					}
				}
			},
			true
		);
		setNoteValue('');
		await refetch();
	};

	const setNewMembership = async (membershipTypeId) => {
		try {
			await graphql(
				{
					query: `#graphql
					mutation ($personId:bigint!,$now:timestamptz!,$input:ClubMembershipSetInput!){
						updateClubMembership(where: {endDate:{_gt:$now}, personId: {_eq:$personId}},_set:$input) {
							affectedRows
						}
					}
					`,
					variables: {
						now: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
						personId: id,
						input: {
							endDate: moment().format('YYYY-MM-DDTHH:mm:ssZ')
						}
					}
				},
				true
			);

			if (membershipTypeId)
				await graphql(
					{
						query: `#graphql
					mutation ($input:ClubMembershipInsertInput!){
						insertClubMembershipOne(object:$input){
							id
						}
					}
					`,
						variables: {
							input: {
								membershipTypeId,
								personId: id,
								startDate: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
								endDate: moment().add(1, 'years').format('YYYY-MM-DDTHH:mm:ssZ')
							}
						}
					},
					true
				);
			await refetch();
		} catch (error) {
			alert(error.message);
		}
	};

	const setMembershipExpiryDate = async (id, date) => {
		try {
			await graphql(
				{
					query: `#graphql
					mutation ($id:bigint!,$date:timestamptz) {
						updateClubMembershipByPk(pkColumns:{id:$id},_set:{endDate:$date}) {
							id
						}
					}
					`,
					variables: {
						id,
						date: moment(date).format('YYYY-MM-DDTHH:mm:ssZ')
					}
				},
				true
			);
			await refetch();
		} catch (error) {
			alert(error.message);
		}
	};

	const deleteCorseCompletion = async (id) => {
		try {
			await graphql(
				{
					query: `#graphql
					mutation ($id:bigint!) {
						deleteCourseCompletionByPk(id:$id) {
							id
						}
					}
					`,
					variables: {
						id
					}
				},
				true
			);
			await refetch();
		} catch (error) {
			alert(error.message);
		}
	};

	const deletePermit = async (id) => {
		try {
			await graphql(
				{
					query: `#graphql
					mutation ($id:bigint!) {
						deletePermitGrantedByPk(id:$id) {
							id
						}
					}
					`,
					variables: {
						id
					}
				},
				true
			);
			await refetch();
		} catch (error) {
			alert(error.message);
		}
	};

	const addPermit = async (id) => {
		try {
			if (!id) return alert('Please select a permit');
			await graphql(
				{
					query: `#graphql
					mutation ($id:bigint!,$personId:bigint!) {
						insertPermitGrantedOne(object:{personId:$personId,permitId:$id}) {
							id
						}
					}
					`,
					variables: {
						id,
						personId: user.id
					}
				},
				true
			);
			await refetch();
			setSelectedPermit(null);
		} catch (error) {
			alert(error.message);
		}
	};

	const unlinkDiscountCode = async (id) => {
		try {
			await graphql(
				{
					query: `#graphql
				mutation ($id:Int!) {
					deletePersonDiscountCodeByPk(id:$id) {
						id
					}
				}
				`,
					variables: {
						id
					}
				},
				true
			);
			await refetch();
		} catch (error) {
			alert(error.message);
		}
	};

	if (isLoading && !data) return <Spinner />;
	return (
		<Row>
			<Col md="5">
				<Card>
					<Table>
						<tbody>
							<tr>
								<th>First Name</th>
								<td>
									{data.firstName}
									<AskText
										value={data.firstName}
										label="First Name"
										type="text"
										onSubmit={(e) => updatePerson({ firstName: e })}
									/>
								</td>
							</tr>
							<tr>
								<th>Last Name</th>
								<td>
									{data.lastName}
									<AskText
										value={data.lastName}
										label="Last Name"
										type="text"
										onSubmit={(e) => updatePerson({ lastName: e })}
									/>
								</td>
							</tr>
							<tr>
								<th>Email</th>
								<td>
									{data.email}
									<AskText value={data.email} label="Email" type="text" onSubmit={(e) => updatePerson({ email: e })} />
								</td>
							</tr>
							<tr>
								<th>Phone</th>
								<td>
									{data.mobile}
									<AskText
										value={data.mobile}
										label="Phone"
										type="text"
										onSubmit={(e) => updatePerson({ mobile: e })}
									/>
								</td>
							</tr>
							<tr>
								<th>Gender</th>
								<td>
									{data.gender ? 'Male' : 'Female'}&nbsp;
									<AskSelect
										title="Gender"
										value={data.gender}
										values={[
											{
												text: 'Male',
												value: true
											},
											{
												text: 'Female',
												value: false
											}
										]}
										onSubmit={(v) => updatePerson({ gender: v === 'true' })}
									/>
								</td>
							</tr>
							<tr>
								<th>Weight</th>
								<td>
									{data.weight}
									<AskText
										value={data.weight}
										label="Weight"
										type="text"
										onSubmit={(e) => updatePerson({ weight: e })}
									/>
								</td>
							</tr>
							<tr>
								<th>Height</th>
								<td>
									{data.height}
									<AskText
										value={data.height}
										label="Height"
										type="text"
										onSubmit={(e) => updatePerson({ height: e })}
									/>
								</td>
							</tr>
							<tr>
								<th>Date of Birth</th>
								<td>
									{data.dob ? moment(data.dob).format('DD/MM/YYYY') : ''}
									<AskText
										value={data.dateOfBirth}
										label="Date of Birth"
										type="date"
										onSubmit={(e) => updatePerson({ dateOfBirth: e })}
									/>
								</td>
							</tr>
							{/* <tr>
								<th>Next Contact</th>
								<td>
									{data.nextContactDate ? moment(data.nextContactDate).format('DD/MM/YYYY') : ''}
									<AskText
										value={data.nextContactDate}
										label="Next Contact"
										type="date"
										onSubmit={(e) => updatePerson({ nextContact: e })}
									/>
								</td>
							</tr> */}
							<tr>
								<th>Marketing Emails</th>
								<td>
									{data.emailMarketing ? 'Yes' : 'No'}&nbsp;
									<AskSelect
										title="Email Marketing"
										value={data.emailMarketing}
										values={[
											{
												text: 'Yes',
												value: true
											},
											{
												text: 'No',
												value: false
											}
										]}
										onSubmit={(v) => updatePerson({ emailMarketing: v === 'true' })}
									/>
								</td>
							</tr>
							<tr>
								<th>Admin Privileges</th>
								<td>
									{data.admin ? 'Yes' : 'No'}&nbsp;
									<AskSelect
										title="Admin"
										value={data.admin}
										values={[
											{
												text: 'Yes',
												value: true
											},
											{
												text: 'No',
												value: false
											}
										]}
										onSubmit={(v) => updatePerson({ admin: v === 'true' })}
									/>
								</td>
							</tr>
							<tr>
								<th>BHPA Number</th>
								<td>
									{data.bhpaNumber}
									<AskText
										value={data.bhpaNumber}
										label="BHPA Number"
										type="text"
										onSubmit={(e) => updatePerson({ bhpaNumber: e })}
									/>
								</td>
							</tr>
							<tr>
								<th>BHPA Expiry</th>
								<td>
									{data.bhpaExpiry ? moment(data.bhpaExpiry).format('DD/MM/YYYY') : ''}
									<AskText
										value={data.bhpaExpiry}
										label="BHPA Expiry"
										type="date"
										onSubmit={(e) => updatePerson({ bhpaExpiry: e })}
									/>
								</td>
							</tr>
						</tbody>
					</Table>
					<Button color="primary" type="submit">
						Save
					</Button>
				</Card>
				<Card className="mt-3">
					<CardHeader>
						<Label for="exampleSelect">Membership Details</Label>
					</CardHeader>
					<Table>
						<tbody>
							<tr>
								<th colSpan="2">
									Current Membership
									<AskSelect
										title="Membership Type"
										description="This will override the current membership type"
										values={data.clubMembershipType.map((t) => ({
											text: t.product.name,
											value: t.id
										}))}
										onSubmit={(v) => setNewMembership(v)}
										required={false}
									>
										<Button size="sm" style={{ float: 'right' }} onClick={() => {}} color="primary" type="submit">
											Apply&nbsp;Membership
										</Button>
									</AskSelect>
								</th>
							</tr>

							{data.currentMembership.length === 0 ? <td>No Current Membership</td> : ''}
							{data.currentMembership.map((d) => {
								return (
									<>
										<tr>
											<td>Type: {d.type.name} </td>
											<td>
												Expiry Date: {new Date(d.endDate).toLocaleDateString('en-GB')}&nbsp;
												<AskDate value={d.endDate} onSubmit={(date) => setMembershipExpiryDate(d.id, date)} />
												&nbsp;&nbsp;
												<AskConfirm title="Remove Membership" onSubmit={() => setNewMembership(null)}>
													<i className="fa fa-times fa-lg" style={{ cursor: 'pointer' }} />
												</AskConfirm>
											</td>
										</tr>
									</>
								);
							})}

							<tr>
								<th colSpan="2">Previous Memberships</th>
							</tr>

							{data.memberships.length === 0 ? <td>No Previous Memberships</td> : ''}
							{data.memberships.map((d) => {
								return (
									<>
										<tr>
											<td>Type: {d.type.name}</td>
											<td>Date Expired: {new Date(d.endDate).toLocaleDateString('en-GB')}</td>
										</tr>
									</>
								);
							})}
						</tbody>
					</Table>
				</Card>

				<Card className="mt-3">
					<CardHeader>
						<Label for="completionSelect">Course Completion</Label>
					</CardHeader>
					<Table
						style={{
							marginBottom: '0px'
						}}
					>
						<thead>
							<tr>
								<th>Course</th>
								<th>Date</th>
								<th>Notes</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{data.completedCourses.map((b, i) => {
								return (
									<tr>
										<td>{b.activity.name}</td>
										<td>{new Date(b.date).toLocaleDateString('en-GB')}</td>
										<td>
											{' '}
											<i
												href="#"
												id={`tooptip${i}`}
												className={b.notes ? 'fa-solid fa-note-sticky' : 'fa-regular fa-note-sticky'}
											/>
											{b.notes && (
												<UncontrolledTooltip placement="right" target={`tooptip${i}`}>
													{/* note icon */}
													{b.notes}
												</UncontrolledTooltip>
											)}
										</td>
										<td>
											<AskConfirm title="Remove Course Completion" onSubmit={() => deleteCorseCompletion(b.id)}>
												<i className="fa fa-times fa-lg" style={{ cursor: 'pointer' }} />
											</AskConfirm>
										</td>
									</tr>
								);
							})}
							{!data.completedCourses.length && (
								<tr>
									<td
										style={{
											textAlign: 'center'
										}}
										colSpan={3}
									>
										No completed courses
									</td>
								</tr>
							)}
						</tbody>
					</Table>
					<AddCourseCompletion personId={id} onSubmit={refetch}>
						<Button block color="primary" className="mt-1 btn-block">
							Add Completion
						</Button>
					</AddCourseCompletion>
				</Card>

				<Card className="mt-3">
					<CardHeader>
						<Label for="completionSelect">Assigned Discount Codes</Label>
					</CardHeader>
					<ListGroup>
						{data.personDiscountCodes?.map(({ discountCode: d, id }, i) => {
							return (
								<ListGroupItem>
									<div style={{ float: 'right' }}>
										<i
											href="#"
											id={`codeTooltip${i}`}
											className={d.note ? 'fa-solid fa-note-sticky' : 'fa-regular fa-note-sticky'}
										/>
										&nbsp;
										<AskConfirm title="Unlink Discount Code" onSubmit={() => unlinkDiscountCode(id)}>
											<i style={{ cursor: 'pointer' }} className="fa fa-times" />
										</AskConfirm>
									</div>
									{d.name} <br />
									{d.expiry && moment(d.expiry).isBefore(moment()) ? (
										<b style={{ color: 'red' }}>Expired</b>
									) : (
										// days left
										<small>Expires {moment(d.expiry).fromNow()}</small>
									)}
									<small style={{ float: 'right' }}>{d.id}</small>
									{d.note && (
										<UncontrolledTooltip placement="right" target={`codeTooltip${i}`}>
											{/* note icon */}
											{d.note}
										</UncontrolledTooltip>
									)}
								</ListGroupItem>
							);
						})}
					</ListGroup>
					<AddDiscountCode personId={id} onSubmit={refetch}>
						<Button block color="primary" className="mt-1 btn-block">
							Add Discount Code
						</Button>
					</AddDiscountCode>
				</Card>

				{/* <Card className="mt-3">
					<CardHeader>
						<Label for="exampleSelect">Email Template</Label>
					</CardHeader>
					<Input
						type="select"
						name="select"
						id="exampleSelect"
						value={emailTemplate}
						onChange={(e) => setEmailTemplate(e.target.value)}
					>
						<option value={0}>Please Select</option>
						{emailTemplates.map((t) => {
							return <option value={t.template_id}>{t.template_name}</option>;
						})}
					</Input>
					<Button onClick={emailPilot} color="primary" type="submit" className="mt-1 btn-block">
						Email Person
					</Button>
				</Card> */}

				{/* <Card className="mt-3">
					<CardHeader>
						<Label for="exampleSelect">Club Membership</Label>
					</CardHeader>
					<Input
						type="select"
						name="select"
						id="exampleSelect"
						value={emailTemplate}
						onChange={(e) => setEmailTemplate(e.target.value)}
					>
						<option value={0}>Please Select</option>
						{emailTemplates.map((t) => {
							return <option value={t.template_id}>{t.template_name}</option>;
						})}
					</Input>
					<Button onClick={emailPilot} color="primary" type="submit" className="mt-1 btn-block">
						Add Membership
					</Button>
				</Card> */}

				{user.grantedPermits.find((p) => p.permit.name === 'GRANT_PERMIT') && (
					<Card className="mt-3">
						<CardHeader>
							<Label for="exampleSelect">Permit Management</Label>
						</CardHeader>
						<CardBody>
							<Table
								style={{
									marginBottom: '0px'
								}}
							>
								<thead>
									<tr>
										<th>Permit</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{data.grantedPermits.map((b) => {
										return (
											<tr>
												<td>{b.permit.name}</td>
												<td>
													<AskConfirm
														title="Remove Permit"
														description={`Are you sure you want to remove the permit ${b.permit.name}?`}
														onSubmit={() => deletePermit(b.id)}
													>
														<i className="fa fa-times fa-lg" style={{ cursor: 'pointer' }} />
													</AskConfirm>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</CardBody>
						<Input
							type="select"
							name="select"
							id="exampleSelect"
							value={selectedPermit}
							onChange={(e) => setSelectedPermit(e.target.value)}
						>
							<option value={0}>Please Select</option>
							{data.permit.map((p) => {
								if (data.grantedPermits.find((x) => x.permit.id === p.id)) return null;
								return <option value={p.id}>{p.name}</option>;
							})}
						</Input>
						<Button onClick={() => addPermit(selectedPermit)} color="primary" type="submit" className="mt-1 btn-block">
							Add permit
						</Button>
					</Card>
				)}
			</Col>
			<Col md="7">
				<Card>
					<CardHeader>
						<Label>Activity Bookings</Label>
						<br />
						{selectedBookings.length > 0 && (
							<AskSelect
								title="Move to another person"
								description="Use the search box to filter down the list of people"
								values={data.people.map((p) => ({
									text: p.name + ' - ' + p.email,
									value: p.id
								}))}
								onSubmit={(v) => moveBookings(v)}
								search
							>
								<Button size="sm" color="secondary">
									Move to another person
								</Button>
							</AskSelect>
						)}
					</CardHeader>
					<Table>
						<thead>
							<tr>
								<th></th>
								<th>Activity</th>
								<th>Booking Status</th>
								<th>Expires</th>
								<th>
									<AddParticipationModal personId={id}>
										<Button size="sm" color="primary">
											<i className="fa fa-plus" />
										</Button>
									</AddParticipationModal>
								</th>
							</tr>
						</thead>
						<tbody>
							{data.participations.map((b) => {
								return (
									<tr>
										{/* Checkbox */}
										<td>
											<Input
												type="checkbox"
												onChange={() => toggleSelectedBooking(b.id)}
												checked={selectedBookings.includes(b.id)}
											/>
										</td>
										<td>
											<ParticipationModal participationId={b.id} onSubmit={refetch}>
												<span style={{ textDecoration: 'underline', cursor: 'pointer', color: '#118443 ' }}>
													{b.activity.name}
												</span>
											</ParticipationModal>
										</td>
										<td>
											{b.date && moment(b.date).format('DD/MM/YYYY')}
											{moment(b.expiresAt).isBefore(moment()) && !b.date ? (
												<b style={{ color: 'red' }}>Expired</b>
											) : (
												!b.date && 'No Date'
											)}
											<br />
											{b.status.name}
										</td>
										<td>
											{b.expiresAt ? moment(b.expiresAt).format('DD/MM/YYYY') : ''}
											<br />
											{b.expiresAt ? moment(b.expiresAt).fromNow() : ''}
										</td>
										<td></td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Card>
				<div className="my-3" />
				<Card>
					<CardHeader>
						<Label>Workshop Bookings</Label>
					</CardHeader>
					<Table>
						<thead>
							<tr>
								<th>Service</th>
								<th>Date</th>
								<th>Status</th>
								<th>
									<AddWorkshopBookingModal personId={id}>
										<Button size="sm" color="primary">
											<i className="fa fa-plus" />
										</Button>
									</AddWorkshopBookingModal>
								</th>
							</tr>
						</thead>
						<tbody>
							{data.workshopBookings.map((b) => {
								if (!b.workshopBookingService) return null;
								return (
									<tr>
										<td>{b.workshopBookingService?.product?.name}</td>
										<td>{b.date}</td>
										<td>{b.status.name}</td>
										<td>
											<Button size="sm" color="primary" onClick={() => history(`/admin/workshop/${b.id}`)}>
												View
											</Button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Card>
			</Col>
			<Col md={12}>
				<Card className="mt-3">
					<CardHeader>
						<Label for="exampleSelect">Staff Notes</Label>
					</CardHeader>
					<div
						style={{
							padding: '5px 15px',
							display: data.staffNotes ? 'block' : 'none'
						}}
						dangerouslySetInnerHTML={{ __html: data.staffNotes }}
					/>

					<Input
						type="textarea"
						// name="select"
						id="exampleSelect"
						value={noteValue}
						onChange={(e) => setNoteValue(e.target.value)}
					>
						<option value={0}>Please Select</option>
						{emailTemplates.map((t) => {
							return <option value={t.template_id}>{t.template_name}</option>;
						})}
					</Input>
					<Button onClick={addStaffNote} color="primary" type="submit" className="mt-1 btn-block">
						Add Note
					</Button>
				</Card>
			</Col>
		</Row>
	);
}
