import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, Input } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import Spinner from 'src/components/Spinner';
import graphql from 'src/utils/graphql';

function ManageDiscountsDialog({ children, discountCode }) {
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [discounts, setDiscounts] = useState();

	const { data, isLoading, error } = useQuery(
		['ManageDiscountsDialog'],
		async () => {
			const { product, discountActivity } = await graphql(
				{
					query: `
					query ($discountCode:String!){
						product(where:{cartable:{_eq:true}}) {
							id
							name
							price
						}
						discountActivity(where:{discountId:{_eq:$discountCode}}) {
							product {
								id
								name
								price
							}
							discount:gbp
						}
					}
				`,
					variables: { discountCode }
				},
				true
			);
			setDiscounts(discountActivity);
			return product;
		},
		{
			enabled: modal,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			refetchInterval: false
		}
	);

	const save = async () => {
		console.log(discounts);

		// Delete all old discounts
		await graphql(
			{
				query: `
			mutation ($discountCode:String!){
				deleteDiscountActivity(where:{discountId:{_eq:$discountCode}}) {
					affectedRows
				}
			}`,
				variables: { discountCode }
			},
			true
		);

		// Add new discounts
		await graphql(
			{
				query: `
			mutation ($discountActivity:[DiscountActivityInsertInput!]!){
				insertDiscountActivity(objects:$discountActivity) {
					affectedRows
				}
			}`,
				variables: {
					discountActivity: discounts.map((d) => ({
						discountId: discountCode,
						productId: d.product.id,
						gbp: d.discount
					}))
				}
			},
			true
		);
	};

	return (
		<>
			<span onClick={toggle}>{children || 'open'}</span>
			<Modal isOpen={modal} toggle={toggle} size="lg">
				<ModalHeader toggle={toggle}>Discount Products</ModalHeader>
				<ModalBody>
					{console.log(discounts)}
					{isLoading ? (
						<Spinner />
					) : (
						data.map((product) => (
							<div
								key={product.id}
								style={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: '3px',
									borderBottom: '1px solid #ccc'
								}}
							>
								<InputGroup
									style={{
										width: '100px'
									}}
								>
									<InputGroupText>£</InputGroupText>
									<Input
										type="number"
										value={discounts?.find((d) => d.product.id === product.id)?.discount || 0}
										onChange={(e) => {
											if (e.target.value < 0) return;
											const newDiscounts = [...discounts];
											const index = newDiscounts.findIndex((d) => d.product.id === product.id);
											if (!e.target.value || e.target.value === '0') {
												if (index !== -1) {
													newDiscounts.splice(index, 1);
												}
											} else {
												if (index === -1) {
													newDiscounts.push({
														product,
														discount: Number(e.target.value)
													});
												} else {
													newDiscounts[index].discount = Number(e.target.value);
												}
											}
											setDiscounts(newDiscounts);
										}}
										placeholder="username"
									/>
								</InputGroup>
								&nbsp;
								<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
									<div>{product.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
									<div
										style={{
											fontStyle: 'italic'
										}}
									>
										(Usually <b>£{product.price}</b>) &nbsp;(After Discount&nbsp;
										<b>£{product.price - (discounts?.find((d) => d.product.id === product.id)?.discount || 0)}</b>)
									</div>
								</div>
							</div>
						))
					)}
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={save}>
						Save Discounts
					</Button>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default ManageDiscountsDialog;
