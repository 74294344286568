import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Spinner, Alert } from 'reactstrap';
import { useApp } from 'src/components/App/appContext';

export default function Login() {
	const { setAccessToken, isLoaded, user, error } = useApp();
	const { token } = useParams();
	const navigate = useNavigate();
	const [tried, setTried] = useState(false);

	console.log('Login in with magic link token: ', token);

	useEffect(() => {
		if (tried) return;
		setAccessToken(token);
		setTried(true);
	}, [token, setAccessToken]);

	useEffect(() => {
		if (user) navigate('/');
	}, [user]);

	return (
		<>
			{isLoaded ? (
				error && (
					<Alert color="danger">
						<b>Failed to login:</b> <br />
						{error}
					</Alert>
				)
			) : (
				<Spinner />
			)}
		</>
	);
}
