import { useState, useEffect, useCallback } from 'react';
import axios from '../../../utils/axios';
import { Table, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import Spinner from 'src/components/Spinner';

import { useApp } from 'src/components/App/appContext';
export default function Historic() {
	const { get } = useApp();

	return (
		<>
			<Table>
				<thead>
					<tr>
						<th>Service Name</th>
						<th>Service Text</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Forename</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Surname</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Address</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Telephone Number</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Email</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Date of Birth</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Age</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Height (cm)</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Weight (kg)</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>BHPA Number</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Last Active Date</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Activity</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Total days attended</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Qualified?</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Tow Conversion</td>
						<td>
							<Input />
						</td>
					</tr>
					<tr>
						<td>Notes</td>
						<td>
							<Input />
						</td>
					</tr>
				</tbody>
			</Table>
		</>
	);
}
