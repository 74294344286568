import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL
});

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => Promise.reject(new Error((error.response && error.response.data) || 'Something went wrong'))
);

export default axiosInstance;
