import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Input, FormGroup, Label, Alert, Card } from 'reactstrap';
import graphql from 'src/utils/graphql';
import { CartContext } from 'src/contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../App/appContext';
import { AskLogin } from '../AskComponents';
import LoadingButton from '../LoadingButton';

function CodeChecker({ children, title = 'Redeem Code' }) {
	const { user } = useApp();
	const cart = useContext(CartContext);
	const navigate = useNavigate();
	const [modal, setModal] = useState(false);
	const toggle = () => {
		resetModal();
		setModal(!modal);
	};

	const [code, setCode] = useState('');
	const [codeData, setCodeData] = useState(null);
	const [codeMessage, setCodeMessage] = useState(null);
	const [nextAction, setNextAction] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const resetModal = () => {
		setCode('');
		setCodeData(null);
		setCodeMessage(null);
		setError(null);
	};

	const fetchCode = async () => {
		try {
			setCodeData(null);
			setError(null);
			if (!code) return setError('Please enter a code');
			setLoading(true);
			const {
				checkCode: { message, product, next }
			} = await graphql({
				query: `query($code: String!) {
					checkCode(code:$code) {
						product {
							name
							description
							price
							image {
								url
							}
						}
						message
						next
					}
				}`,
				variables: {
					code
				}
			});
			if (product) setCodeData(product);
			if (message) setCodeMessage(message);
			if (next) setNextAction(next);
		} catch (error) {
			console.log(error);
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	const redeemVoucher = async (code) => {
		try {
			setLoading(true);
			const { redeemVoucher } = await graphql({
				query: `mutation($code: ID!) {
					redeemVoucher(code:$code) 
				}
				`,
				variables: {
					code
				}
			});
			if (redeemVoucher) {
				setCodeMessage('Voucher Redeemed Successfully');
				navigate('/bookings');
			} else {
				setCodeMessage('Voucher Redeem Failed');
			}
		} catch (error) {
			console.log(error);
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	const redeemCode = async () => {
		switch (nextAction) {
			case 'CHECKOUT':
				cart.addCode(code);
				navigate('/cart');
				break;

			case 'ACTIVITIES':
				cart.addCode(code);
				navigate('/choose-sport');
				break;

			case 'REDEEM':
				if (user) return redeemVoucher(code);
				setCodeMessage('Please login to redeem voucher');
				break;

			default:
				break;
		}

		setModal(false);
	};

	return (
		<div>
			<span onClick={toggle}>{children || <Button color="danger">Click Me</Button>}</span>
			<Modal size="lg" isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>{title}</ModalHeader>
				<ModalBody>
					{error && (
						<Alert style={{ padding: '7px' }} color="danger">
							{error}
						</Alert>
					)}

					{codeMessage && (
						<Alert style={{ padding: '7px' }} color="info">
							{codeMessage}
						</Alert>
					)}

					{!codeData && !codeMessage && (
						<FormGroup floating>
							<Input
								className="mb-2"
								type="text"
								id="code"
								placeholder="Enter Code"
								value={code}
								onChange={(e) => setCode(e.target.value)}
							/>

							<Label for="code">Code</Label>
						</FormGroup>
					)}
					{codeData && (
						<div className="row">
							<div className="col-md-8">
								<img className="img-fluid" style={{ width: '500px' }} src={codeData.image.url} alt={codeData.name} />
							</div>
							<div className="col-md-4">
								<h5>{codeData.name}</h5>
								<p>{codeData.description}</p>

								{user || nextAction !== 'REDEEM' ? (
									<LoadingButton
										loading={loading}
										style={{ width: '100%' }}
										color="success"
										className="mt-2"
										onClick={redeemCode}
									>
										{{
											CHECKOUT: <>Apply &amp; Go to Checkout</>,
											ACTIVITIES: <>Apply &amp; Browse Activities</>,
											REDEEM: <>Redeem Code</>
										}[nextAction] || <>Redeem</>}
									</LoadingButton>
								) : (
									<AskLogin
										style={{ width: '100%' }}
										buttonMessage="Login to redeem"
										message="Please login or register to redeem your voucher"
									/>
								)}
							</div>
						</div>
					)}
					{!codeData && (
						<LoadingButton
							loading={loading}
							style={{ width: '100%' }}
							color="primary"
							className="mt-2"
							onClick={fetchCode}
						>
							Check Code
						</LoadingButton>
					)}
				</ModalBody>
			</Modal>
		</div>
	);
}

export default CodeChecker;
