import React from 'react';
import ClientLink from '../../../components/ClientLink';
import { useQuery } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import Spinner from 'src/components/Spinner';

export default function ChooseSport() {
	const { data, isLoading, error } = useQuery(['sports'], async () => {
		const { sport } = await graphql(
			{
				query: `#graphql
				{
					sport {
						id
						name
					}
				}`
			},
			true
		);
		return { sports: sport };
	});

	if (isLoading) return <Spinner />;
	return (
		<div>
			{data.sports.map((sport) => (
				<ClientLink
					key={sport.id}
					title={sport.name}
					icon="fa fa-fw fa-caret-right"
					text="See the trial days and courses we offer"
					to={`/enrol/${sport.id}`}
				/>
			))}
			<ClientLink title="Back" icon="fa fa-fw fa-arrow-left" text="Head back to the homepage" to="/homepage" />
		</div>
	);
}
