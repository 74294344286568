import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col } from 'reactstrap';
import axios from 'src/utils/axios';
import swal from 'src/utils/swal';

const ActivityModal = (props) => {
	const { activity_data, activities, onDone, children } = props;
	const [activity, setActivity] = useState({ ...activity_data });

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const handleChange = (event) => {
		const newAct = { ...activity };
		newAct[event.target.name] = event.target.value;
		setActivity(newAct);
	};
	const handleDiscountChange = (event, discount_index) => {
		// Copy activity
		const _activity = { ...activity };
		// Copy discount
		const _discount = { ...activity['discounts'][discount_index] };
		// Modify discount
		_discount[event.target.name] = event.target.value;

		// Update activity with modified discount
		_activity['discounts'][discount_index] = _discount;

		setActivity(_activity);
	};

	//   useEffect(() => {
	//     // Validate
	//     let errors_array = {};

	//     if (activity.activity_name.length < 4)
	//       errors_array["activity_name"] = "Please fill in";

	//     if (errors_array) setErrors(errors_array);
	//   }, [activity]);

	const save = async () => {
		try {
			await axios.post('/activities/save', { ...activity });
			setModal(false);
			if (onDone) onDone();
		} catch (error) {
			alert('Something went wrong !');
		}
	};

	const addDiscount = async (activity_id) => {
		var _activity = { ...activity };
		_activity['discounts'].push({
			paid_activity_id: 0,
			discount_price: 0
		});
		setActivity(_activity);
	};

	const deleteDiscount = async (discount_id) => {
		swal
			.fire({
				title: 'Are you sure you want to delete this discount?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!'
			})
			.then((result) => {
				if (result.isConfirmed) {
					axios.get(`/activities/delete-discount/${discount_id}`).then(() => {
						swal.fire('Deleted!', 'Your file has been deleted.', 'success');

						// Remove from ui
						var _activity = { ...activity };
						var index = _activity.discounts.findIndex((d) => d.discount_id === discount_id);
						_activity.discounts.splice(index, 1);
						setActivity(_activity);
					});
				}
			});
	};

	return (
		<div>
			{!children && (
				<Button onClick={toggle} color="outline-primary" className="float-right">
					<i className="far fa-edit"></i> Edit
				</Button>
			)}
			{children && <div onClick={toggle}>{children}</div>}

			<Modal size="lg" toggle={toggle} isOpen={modal}>
				<ModalHeader>Modal title</ModalHeader>
				<ModalBody>
					Actvity Name
					<Input
						required
						className="mt-1 mb-3"
						value={activity.activity_name}
						name="activity_name"
						onChange={handleChange}
						// invalid={errors["activity_name"] ? true : false}
						// valid={errors["activity_name"] ? false : true}
					/>
					Description
					<Input
						required
						className="mt-1 mb-3"
						value={activity.activity_description}
						name="activity_description"
						type="textarea"
						rows={4}
						onChange={handleChange}
					/>
					Price
					<Input
						required
						className="mt-1 mb-3"
						value={activity.activity_price}
						name="activity_price"
						onChange={handleChange}
						type="number"
					/>
					<hr />
					Customer can book
					<Input
						className="mt-1 mb-3"
						value={activity.participation_count}
						name="participation_count"
						type="select"
						onChange={handleChange}
					>
						{[...Array(20)].map((ele, i) => {
							var days = i + 1;
							if (days === 1) return <option value={days}>Single day only</option>;
							return <option value={days}>Approx. {days} Days</option>;
						})}
					</Input>
					Image URL
					<Input className="mt-1 mb-3" value={activity.activity_image} name="activity_image" onChange={handleChange} />
					Display to public
					<Input
						className="mt-1 mb-3"
						value={activity.activity_hidden}
						name="activity_hidden"
						type="select"
						onChange={handleChange}
					>
						<option value={0}>Show activity from public</option>
						<option value={1}>Hide activity from public</option>
					</Input>
					<hr />
					<Button
						onClick={() => addDiscount(activity.activity_id)}
						color="outline-primary"
						className="btn-block btn-sm mb-2"
					>
						<i className="fa fa-plus" /> Add Discount
					</Button>
					{activity.discounts && (
						<>
							{activity.discounts.map((d, index) => {
								return (
									<div
										style={{ border: '2px solid #118443', padding: '10px', borderRadius: '10px', marginBottom: '10px' }}
									>
										<Row>
											<Col md="5">
												<>If Payed For:</>
												<Input
													className="mt-1 mb-3"
													value={d.paid_activity_id}
													name="paid_activity_id"
													type="select"
													onChange={(e) => handleDiscountChange(e, index)}
												>
													<option value={0}> N/A</option>
													{activities.map((a) => {
														return <option value={a.activity_id}>{a.activity_name}</option>;
													})}
												</Input>
											</Col>
											<Col md="7">
												<i
													onClick={() => deleteDiscount(d.discount_id)}
													className="fa fa-times float-right"
													style={{ color: 'red', cursor: 'pointer' }}
												/>
												<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
													Discount £{d.discount_price} from {activity.activity_name}
												</div>
												<Input
													required
													className="mt-1 mb-3"
													value={d.discount_price}
													name="discount_price"
													onChange={(e) => handleDiscountChange(e, index)}
													type="number"
												/>
											</Col>
										</Row>
									</div>
								);
							})}
						</>
					)}
					{/* <div className="mb-1">Discount Activity:</div>
          If already paid for activity
          <Input
            className="mt-1 mb-3"
            value={activity.discount_activity}
            name="discount_activity"
            type="select"
            onChange={handleChange}
          >
            <option value={0}> N/A</option>
            {activities.map((a) => {
              return <option value={a.activity_id}>{a.activity_name}</option>;
            })}
          </Input>
          {activity.discount_activity && activity.discount_activity !== 0 && (
            <>
              Discount Price
              <Input
                required
                className="mt-1 mb-3"
                value={activity.discount_price}
                name="discount_price"
                onChange={handleChange}
                type="number"
              />
            </>
          )} */}
				</ModalBody>
				<ModalFooter>
					<Button onClick={save} color="primary">
						Save
					</Button>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default ActivityModal;
