import { useState } from 'react';
import { Button, FormGroup, Input, Label, Alert } from 'reactstrap';
import { useApp } from 'src/components/App/appContext';
import { useParams } from 'react-router-dom';
import LoadingButton from 'src/components/LoadingButton';

export default function ForgotPassword() {
	const { token } = useParams();
	const { resetPassword } = useApp();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [resetDone, setResetDone] = useState(false);

	const handleResetPassword = async () => {
		try {
			if (!password || !confirmPassword) return setError('Please fill in all fields');
			if (password !== confirmPassword) return setError('Passwords do not match');
			setLoading(true);
			await resetPassword(token, password);
			setResetDone(true);
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				maxWidth: '600px',
				margin: 'auto',
				marginTop: '2vh',
				textAlign: 'center'
			}}
		>
			<h1>Set new password</h1>
			<p>Enter your new password below.</p>
			{error && <Alert color="danger">{error}</Alert>}
			{resetDone && <Alert color="success">Password reset successfully!</Alert>}
			<FormGroup floating>
				<Input
					size={'small'}
					type="Password"
					name="Password"
					id="Password"
					placeholder="Password"
					onChange={(e) => setPassword(e.target.value)}
				/>
				<Label for="password">Password</Label>
			</FormGroup>
			<FormGroup floating>
				<Input
					type="Password"
					name="Confirm Password"
					id="Confirm Password"
					placeholder="Confirm Password"
					onChange={(e) => setConfirmPassword(e.target.value)}
				/>
				<Label for="confirmPassword">Confirm Password</Label>
			</FormGroup>

			<LoadingButton loading={loading} color="primary" style={{ width: '100%' }} onClick={handleResetPassword}>
				Update Password
			</LoadingButton>
		</div>
	);
}
