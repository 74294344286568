import React from 'react';
import { Link } from 'react-router-dom';
import styles from './homepage.module.css';

export default function ClientLink(props) {
	const { title, icon, text, to } = props;
	return (
		<Link to={to ?? null} style={{ textDecoration: 'none', color: 'black' }}>
			<div className={styles.homepageLink + ' mb-3'}>
				<h2 style={{ fontWeight: '400' }}>
					<i className={icon} style={{ color: 'hsl(134, 20%, 70%)' }} />
					&nbsp;{title}
				</h2>
				<span>{text}</span>
			</div>
		</Link>
	);
}
