import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Alert } from 'reactstrap';
import graphql from 'src/utils/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useApp } from 'src/components/App/appContext';

export default function DiscountDialog({ sx, children, discountCode, ...props }) {
	const { user } = useApp();
	const queryClient = useQueryClient();
	const [modal, setModal] = useState(false);
	const initialState = discountCode
		? discountCode
		: {
				name: '',
				id: '', //code
				expiry: '',
				singleUse: false
		  };
	const [state, setState] = useState(initialState);
	const [error, setError] = useState(null);

	const toggle = () => {
		if (modal) handleClose();
		setModal(!modal);
	};

	const save = async () => {
		try {
			if (discountCode) {
				// Edit
				const { updateDiscountCode } = await graphql(
					{
						query: `
						mutation ($id:String!, $input:DiscountCodeSetInput!) {
							updateDiscountCodeByPk(pkColumns: {id: $id}, _set: $input) {
								id
							}
						}
					`,
						variables: {
							id: discountCode.id,
							// remove createdPerson from state
							input: { ...state, createdPerson: undefined }
						}
					},
					true
				);
			} else {
				// Create
				const { createDiscountCode } = await graphql(
					{
						query: `
						mutation ($input:DiscountCodeInsertInput!){
							insertDiscountCodeOne(object:$input) {
								id
							}
						}`,
						variables: {
							input: {
								...state,
								createdPersonId: user.id
							}
						}
					},
					true
				);
				// Reset to empty boxes
				setState(initialState);
			}
			await queryClient.invalidateQueries('discounts');
			handleClose();
		} catch (error) {
			setError(error.message);
		}
	};

	const handleClose = () => {
		setError();
		setModal(false);
	};

	return (
		<>
			{children ? (
				<span onClick={toggle}>{children}</span>
			) : (
				<Button {...props} sx={sx} color="primary" onClick={toggle}>
					<i className="fa fa-plus" />
					&nbsp; Create Discount
				</Button>
			)}
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>{discountCode ? `Edit ${discountCode.id}` : `Create Discount`}</ModalHeader>
				<ModalBody>
					{error && <Alert color="danger">{error}</Alert>}
					<FormGroup floating>
						<Input
							onChange={(e) => setState({ ...state, name: e.target.value })}
							value={state.name}
							id="name"
							placeholder="name"
							type="text"
						/>
						<Label for="name">Discount Name</Label>
					</FormGroup>

					<FormGroup
						floating
						style={
							discountCode && {
								pointerEvents: 'none',
								opacity: 0.8
							}
						}
					>
						<Input
							onChange={(e) => setState({ ...state, id: e.target.value })}
							value={state.id}
							id="code"
							placeholder="code"
							type="text"
							disabled={discountCode}
						/>
						<Label for="code">Discount Code</Label>
					</FormGroup>

					<FormGroup floating>
						<Input
							onChange={(e) => setState({ ...state, expiry: e.target.value })}
							value={state.expiry}
							id="expiry"
							placeholder="expiry"
							type="date"
						/>
						<Label for="expiry">Expiry Date</Label>
					</FormGroup>

					<FormGroup switch>
						<Input
							onChange={(e) => setState({ ...state, singleUse: Boolean(e.target.checked) })}
							defaultChecked={state.singleUse}
							id="singleUse"
							placeholder="singleUse"
							type="switch"
						/>
						<Label for="singleUse">Single Use</Label>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button disabled={!state.name || !state.id || !state.expiry} color="primary" onClick={save}>
						{discountCode ? 'Update' : 'Create'}
					</Button>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
