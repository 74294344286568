import React from 'react';
import { Button, Spinner } from 'reactstrap';

const LoadingButton = ({ loading, children, disabled, ...props }) => {
	return (
		<Button disabled={disabled || loading} {...props}>
			{loading ? (
				<>
					<Spinner size="sm" className="mr-2" />
					&nbsp; Loading...
				</>
			) : (
				children
			)}
		</Button>
	);
};

export default LoadingButton;
