import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CartContext } from 'src/contexts/CartContext';
import graphql from 'src/utils/graphql';
import Spinner from '../Spinner';

export default function ProductModal({ id, children, defaultOpen }) {
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const cart = useContext(CartContext);
	const [product, setProduct] = useState(null);
	const [addedToCart, setAddedToCart] = useState(false);

	const fetchProducts = useCallback(async () => {
		try {
			const { productByPk } = await graphql(
				{
					query: `#graphql
				query($id: bigint!) {
					productByPk(id:$id) {
						id
						name
						description
						price
						giftable
						imageObject {
							url
						}
					}
				}
			`,
					variables: {
						id
					}
				},
				true
			);
			setProduct(productByPk);
		} catch (error) {
			console.log(error);
			alert("Sorry, we couldn't fetch your cart");
		}
	}, []);

	const addToCart = useCallback(async (id, gift) => {
		cart.addItem(id, gift);
		setAddedToCart(true);
	});

	const open = async () => {
		fetchProducts();
		setModal(true);
		setAddedToCart(false);
	};

	useEffect(() => {
		if (defaultOpen) open();
	}, []);

	return (
		<div>
			<span onClick={() => open()}>{children || 'View Product'}</span>

			<Modal size="lg" isOpen={modal} toggle={toggle}>
				{product ? (
					<>
						<ModalHeader toggle={toggle}>{product.name}</ModalHeader>
						<ModalBody>
							<div className="row">
								{addedToCart && (
									<div className="col-12">
										<Alert color="success" style={{ padding: '7px' }}>
											<div className="row">
												<div className="col-md-9">Item has been added to your cart</div>
												<div className="col-md-3">
													<Link style={{ float: 'right' }} to="/cart">
														<Button color="success" size="sm">
															Go to checkout <i className="fa fa-arrow-right" />
														</Button>
													</Link>
												</div>
											</div>
										</Alert>
									</div>
								)}
								<div className="col-12 col-md-6">
									<img src={product?.imageObject?.url} alt={product.name} className="img-fluid" />
								</div>
								<div className="col-12 col-md-6">
									<p>{product.description}</p>
									<p style={{ fontSize: '1.2em' }}>
										Price: <b>£{product.price}</b>
									</p>
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							{product.giftable && (
								<Button outline color="primary" onClick={() => addToCart(product.id, true)}>
									<i className="fa fa-gift" />
									&nbsp; Purchase as Gift
								</Button>
							)}
							<Button outline color="primary" onClick={() => addToCart(product.id, false)}>
								<i className="fa fa-user" />
								&nbsp; {product.giftable ? 'Purchase for Myself' : 'Purchase'}
							</Button>
						</ModalFooter>
					</>
				) : (
					<ModalBody>
						<div className="mt-3">
							<Spinner noTransform />
						</div>
					</ModalBody>
				)}
			</Modal>
		</div>
	);
}
