import React from 'react';
import { Button, Table, Input } from 'reactstrap';
import moment from 'moment';

export default function StudentPrint({ groups, date }) {
	const Print = () => {
		let printContents = document.getElementById('printablediv').innerHTML;
		let originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();
		window.location.reload(); //reload the page as putting the content back breaks JS anyway
	};

	return (
		<>
			<Button color="primary" style={{ float: 'right' }} onClick={Print}>
				<i className="fa fa-print" />
				&nbsp;Print Pilot List
			</Button>

			<div style={{ display: 'none' }} id="printablediv">
				{console.log(date)}
				<h2 style={{ float: 'right' }}>{moment(date).format('Do MMMM YYYY')}</h2>
				<Table>
					<thead>
						<tr>
							<th>Student</th>
							<th>Details</th>
							<th>Attendance</th>
						</tr>
					</thead>
					<tbody>
						{Object.entries(groups).map(([activityName, students]) => {
							return (
								<>
									<tr>
										<td style={{ textAlign: 'center', backgroundColor: '#eee' }} colspan="3">
											<h5>{activityName}</h5>
										</td>
									</tr>
									{students.map((s) => (
										<tr key={activityName}>
											<td>
												<b style={{ color: s.status.id !== 1 && 'red' }}>
													{s.person.name}
													{s.status.id !== 1 ? ` - ${s.status.name}` : ''}
												</b>
												&nbsp; (Day {s.activityIndex})
												<br />
												{s.person.mobile}
											</td>
											<td>
												Age {dobToAge(new Date(s.person.dob))}, {s.person.weight}kg
											</td>
											<td style={{ fontSize: '1.5em' }}>
												<Input type="checkbox" />
											</td>
										</tr>
									))}
								</>
							);
						})}
					</tbody>
				</Table>
			</div>
		</>
	);
}

function dobToAge(dob) {
	let ageDifMs = Date.now() - dob.getTime();
	let ageDate = new Date(ageDifMs); // miliseconds from epoch
	return Math.abs(ageDate.getUTCFullYear() - 1970);
}
