import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Alert } from 'reactstrap';
import { useApp } from 'src/components/App/appContext';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import Spinner from 'src/components/Spinner';

function MarkInvoicePaidModal({ id, children, ...args }) {
	const queryClient = useQueryClient();
	const { user } = useApp();

	const [modal, setModal] = useState(false);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);

	const toggle = () => {
		if (modal) resetModal();
		setModal(!modal);
	};

	const [personId, setPersonId] = useState(user?.id || '');
	const [notes, setNotes] = useState('');

	const { data, isLoading } = useQuery(
		['MarkInvoicePaidModal'],
		async () =>
			await graphql(
				{
					query: `
					{
						person(where:{admin:{_eq:true}}) {
							id
							name
						}
					}`
				},
				true
			)
	);

	const resetModal = () => {
		setPersonId(user?.id || null);
		setNotes('');
		setSaving(false);
		setError();
	};

	const createInvoicePayment = async () => {
		if (saving) return;
		setSaving(true);
		setError();
		try {
			await graphql(
				{
					query: `
				mutation ($input:PersonInvoicePaidInsertInput!){
					insertPersonInvoicePaidOne(object:$input) {
						id
					}
				}`,
					variables: {
						input: {
							notes: notes,
							invoiceId: id,
							personId: user.id
						}
					}
				},
				true
			);
			resetModal();
			toggle();
			await queryClient.invalidateQueries('invoices');
		} catch (e) {
			setError(e.message);
		} finally {
			setSaving(false);
		}
	};

	return (
		<>
			<span onClick={toggle}>{children || 'open'}</span>
			<Modal isOpen={modal} toggle={toggle} {...args}>
				<ModalHeader toggle={toggle}>Mark Paid</ModalHeader>
				{isLoading ? (
					<ModalBody>
						<Spinner />
					</ModalBody>
				) : (
					<>
						<ModalBody>
							{error && <Alert color="danger">{error}</Alert>}
							<FormGroup floating>
								<Input
									id="personId"
									name="personId"
									placeholder="personId"
									type="select"
									value={personId}
									onChange={(e) => setPersonId(e.target.value)}
								>
									<option value="">Select Payee</option>
									{data?.person?.map((person) => (
										<option key={person.id} value={person.id}>
											{person.name}
										</option>
									))}
								</Input>

								<Label for="personId">Who made this payment?</Label>
							</FormGroup>

							<FormGroup floating>
								<Input
									id="Notes"
									name="Notes"
									placeholder="Notes"
									type="textarea"
									value={notes}
									onChange={(e) => setNotes(e.target.value)}
								/>
								<Label for="Notes">Payment Notes</Label>
							</FormGroup>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={createInvoicePayment}>
								Mark as Paid
							</Button>{' '}
							<Button color="secondary" onClick={toggle}>
								Cancel
							</Button>
						</ModalFooter>
					</>
				)}
			</Modal>
		</>
	);
}

export default MarkInvoicePaidModal;
