import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import { useNavigate } from 'react-router-dom';
import { useApp } from 'src/components/App/appContext';

export default function ServiceModal({ service, children }) {
	const { product, serviceId } = service;
	const [modal, setModal] = useState(false);
	const navigate = useNavigate();
	const toggle = () => setModal(!modal);
	const { user } = useApp();

	const createService = async () => {
		const service = await graphql(
			{
				query: `#graphql
				mutation ($input:WorkshopBookingInsertInput!) {
					insertWorkshopBookingOne(object:$input) {
						id
					}
				}`,
				variables: {
					input: {
						workshopServiceId: serviceId,
						personId: user.id
					}
				}
			},
			true
		);

		navigate(`/servicing/${service.insertWorkshopBookingOne.id}`);
	};

	return (
		<div>
			{console.log(product)}
			<span onClick={toggle}>{children}</span>
			<Modal isOpen={modal} toggle={toggle} size="lg">
				<ModalHeader toggle={toggle}>{product.name}</ModalHeader>
				<ModalBody>
					<Row>
						<Col md="5">
							<img src={product.imageObject?.url} alt={product.name} className="img-fluid" />
						</Col>
						<Col md="7">
							<p>{product.description}</p>
							<p style={{ fontSize: '1.2em' }}>
								Price: <b>£{product.price}</b>&nbsp;
							</p>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button outline color="primary" onClick={createService}>
						Create {product.name}
					</Button>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}
