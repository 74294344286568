import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Form } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Alert } from 'reactstrap';
import Spinner from 'src/components/Spinner';
import graphql from 'src/utils/graphql';

function AddCourseCompletion({ children, onSubmit, personId }) {
	const [modal, setModal] = useState(false);
	const [error, setError] = useState(null);

	const toggle = () => setModal(!modal);

	const { data, isLoading } = useQuery(['courseCompletion', personId], async () => {
		return await graphql(
			{
				query: `#graphql
				{
					activity {
						id
						name
					}
				}
			`
			},
			true
		);
	});

	const [activity, setActivity] = useState(null);
	const [date, setDate] = useState(null);
	const [notes, setNotes] = useState(null);

	const submit = async () => {
		try {
			if (!activity) throw new Error('Please select an activity');
			if (!date) throw new Error('Please select a date');
			setError();

			await graphql(
				{
					query: `#graphql
					mutation($input: CourseCompletionInsertInput!) {
						insertCourseCompletionOne(object: $input) {
							id
						}
					}
					`,
					variables: {
						input: {
							personId,
							activityId: activity,
							date,
							notes
						}
					}
				},
				true
			);
			if (onSubmit) await onSubmit();

			toggle();

			// Reset
			setActivity(null);
			setDate(null);
			setNotes(null);
		} catch (error) {
			setError(error.message);
		}
	};

	return (
		<div>
			<div color="danger" onClick={toggle}>
				{children || 'Open Modal'}
			</div>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Add Completion</ModalHeader>
				<ModalBody>
					{error && <Alert color="danger">{error}</Alert>}
					{isLoading ? (
						<Spinner />
					) : (
						<>
							<FormGroup floating>
								<Input
									onChange={(e) => setActivity(e.target.value)}
									value={activity}
									id="activity"
									placeholder="activity"
									type="select"
								>
									<option value="">Select Activity</option>
									{data.activity
										.sort((a, b) => a.name.localeCompare(b.name))
										.map((e) => (
											<option key={e.id} value={e.id}>
												{e.name}
											</option>
										))}
								</Input>
								<Label for="activity">Activity</Label>
							</FormGroup>

							<FormGroup floating>
								<Input
									onChange={(e) => setDate(e.target.value)}
									value={date}
									id="date"
									placeholder="date"
									type="date"
								/>
								<Label for="date">Date</Label>
							</FormGroup>

							<FormGroup floating>
								<Input
									onChange={(e) => setNotes(e.target.value)}
									value={notes}
									id="notes"
									placeholder="notes"
									type="textarea"
								/>
								<Label for="notes">Notes</Label>
							</FormGroup>
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={submit}>
						Add
					</Button>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

export default AddCourseCompletion;
