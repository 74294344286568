import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle } from 'reactstrap';
import { AskLogin } from 'src/components/AskComponents';

export default function loginFallback() {
	const navigator = useNavigate();
	return (
		<Card className="text-center">
			<CardBody>
				<h4>Please Login to view your profile</h4>
				<br />
				<AskLogin />
				{/* or go home */}
				<h5>or</h5>
				<Button color="secondary" outline onClick={() => navigator('/')}>
					<i className="fa fa-home" />
					&nbsp;Go Home
				</Button>
			</CardBody>
		</Card>
	);
}
