import React, { useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { Button, Alert } from 'reactstrap';
import Spinner from 'src/components/Spinner';
import graphql from 'src/utils/graphql';
import ClosureModal from './ClosureModal';
import ActivityModal from './ActivityModal';
import useMediaQuery from 'src/utils/useMediaQuery';

export default function Calendar() {
	const isMobile = useMediaQuery('(max-width: 599px)');
	const [closureState, setClosureState] = useState();
	const [activityState, setActivityState] = useState();

	const { data, error, isLoading } = useQuery(['calendar'], async () => {
		const { activityAvailability, closeDate } = await graphql(
			{
				query: `
				{
					activityAvailability {
						id
						activity {
							id
							name
						}
						availableSlots
						startDate
						endDate
					}
					closeDate {
						id
						closureReason {
							id
							name
						}
						closureEndDate
						closureStartDate
					}
				}
			`
			},
			true
		);
		return { activityAvailability, closeDate };
	});
	const events = {
		activityAvailability: data?.activityAvailability.map((e) => {
			return {
				title: `x${e.availableSlots} - ${e.activity.name}`,
				start: e.startDate,
				end: moment(e.endDate).add(1, 'days').format('YYYY-MM-DD'),
				// blue
				backgroundColor: '#198754',
				borderColor: '#198754',
				type: 'activityAvailability',
				data: {
					id: e.id,
					activityId: e.activity.id,
					slots: e.availableSlots,
					startDate: e.startDate,
					endDate: e.endDate
				}
			};
		}),
		closeDate: data?.closeDate.map((e) => {
			return {
				title: `${e.closureReason.name}`,
				start: e.closureStartDate,
				end: moment(e.closureEndDate).add(1, 'days').format('YYYY-MM-DD'),
				// red
				backgroundColor: '#FF0000',
				borderColor: '#FF0000',
				type: 'closure',
				data: {
					id: e.id,
					reasonId: e.closureReason.id,
					startDate: e.closureStartDate,
					endDate: e.closureEndDate
				}
			};
		})
	};
	if (isLoading) return <Spinner />;

	return (
		<>
			<Alert
				style={{
					backgroundColor: '#272a2f',
					borderColor: '#747c8b',
					color: 'white',
					textAlign: 'center'
				}}
				className="my-2"
				color="info"
			>
				<h5>Attention</h5>
				Please be aware, any activities not listed on a day will automatically have 6 slots available, unless specified
				otherwise.
			</Alert>
			<div className="mb-3">
				<Button
					color="primary"
					onClick={() => {
						setActivityState({
							activityId: '',
							slots: 0,
							startDate: '',
							endDate: ''
						});
					}}
				>
					<i className="fa-solid fa-circle-plus" />
					&nbsp;Add Availability
				</Button>
				&nbsp;
				<Button
					color="danger"
					onClick={() => {
						setClosureState({
							reasonId: '',
							startDate: '',
							endDate: ''
						});
					}}
				>
					<i className="fa-regular fa-circle-xmark"></i>&nbsp;Add closure
				</Button>
			</div>

			<FullCalendar
				plugins={[dayGridPlugin, listPlugin]}
				initialView={isMobile ? 'listMonth' : 'dayGridMonth'}
				events={events.activityAvailability.concat(events.closeDate)}
				height={'auto'}
				eventClick={(e) => {
					e.event.extendedProps.type === 'closure' && setClosureState(e.event.extendedProps.data);
					e.event.extendedProps.type === 'activityAvailability' && setActivityState(e.event.extendedProps.data);
				}}
			/>
			<ClosureModal state={closureState} setState={setClosureState} />
			<ActivityModal state={activityState} setState={setActivityState} />
		</>
	);
}
