import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Table, Button, Card, CardTitle, CardBody, CardText, Row, Col, UncontrolledCollapse } from 'reactstrap';
import Spinner from 'src/components/Spinner';
import { useNavigate } from 'react-router-dom';
import { useApp } from 'src/components/App/appContext';
import { useQuery } from '@tanstack/react-query';
import graphql from 'src/utils/graphql';
import EditProduct from 'src/components/EditProduct';
import styled from 'styled-components';
import WorkshopBooking from './WorkshopBooking';

const StyledTableContainer = styled.div`
	overflow-x: auto;
	@media (min-width: 768px) {
		overflow-x: visible;
	}
`;

const FilterContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 1rem;
`;

export default function Servicing() {
	const navigate = useNavigate();
	const { user } = useApp();
	const [selectedStatus, setSelectedStatus] = useState('default');

	const { data, isLoading, refetch } = useQuery(
		['myWorkshopBookings', selectedStatus],
		async () => {
			const data = await graphql(
				{
					query: `#graphql
				query ($statusIds: [bigint!]){
					workshopBooking(where: {statusId:{_in:$statusIds}} orderBy: {statusId: ASC, date: ASC}) {
						id
						person {
							name
						}
						equipmentType {
							name
						}
						equipmentMake
						equipmentModel
						date
						workshopBookingService {
    						product {
        						id
        						name
    						}
    					}
						status{
    						id
    						name
    					}
					}
					workshopBookingStatus {
						id
						name
  					}
					workshopService(orderBy:{listOrder:ASC}) {
						serviceId
						product {
							id
							description
							name
							price
							visible
							imageObject {
								url
							}
						}
					}
				}
			`,
					variables: {
						statusIds: selectedStatus === 'default' ? [1, 2, 3, 4, 6] : [selectedStatus]
					}
				},
				true
			);
			return { ...data };
		},
		{ enabled: !!user }
	);

	useEffect(() => {
		if (!user) return navigate('/');
	}, [user]);

	if (isLoading) return <Spinner />;

	return (
		<div>
			<h3 className="">Workshop Services</h3>
			<Row className="gx-3">
				{data.workshopService.map((s, i) => {
					if (!s.product) return null;
					if (!s.product.visible) return null;
					return (
						<Col key={s.product.id} md={3} lg={2}>
							<EditProduct id={s.product?.id} onSubmit={refetch}>
								<Card style={{ cursor: 'pointer' }}>
									<img src={s.product?.imageObject?.url || '/no-image.png'} alt={s.product.name} />
									<CardBody>
										<CardTitle>
											{s.product.name}
											<br />
											<b>£{s.product.price}</b>
										</CardTitle>

										<CardText>{s.product.description}</CardText>
									</CardBody>
								</Card>
							</EditProduct>
						</Col>
					);
				})}
			</Row>
			{data.workshopService.filter((s) => s.product).some((s) => !s.product.visible) && (
				<>
					<Button outline className="mt-2" color="primary" id="toggler" style={{ marginBottom: '1rem' }}>
						Toggle Hidden Services
					</Button>
					<UncontrolledCollapse toggler="#toggler">
						<Row className="gx-3">
							{data.workshopService.map((s, i) => {
								if (!s.product) return null;
								if (s.product.visible) return null;
								return (
									<Col key={s.product.id} md={3} lg={2}>
										<EditProduct id={s.product?.id} onSubmit={refetch}>
											<Card style={{ cursor: 'pointer' }}>
												<img src={s.product?.imageObject?.url || '/no-image.png'} alt={s.product.name} />
												<CardBody>
													<CardTitle>{s.product.name}</CardTitle>
													<CardText>{s.product.description}</CardText>
												</CardBody>
											</Card>
										</EditProduct>
									</Col>
								);
							})}
						</Row>
					</UncontrolledCollapse>
				</>
			)}
			<h3 className="mt-5">Workshop Bookings</h3>
			<FilterContainer>
				<select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
					<option value="default">All Except Collected</option>
					{data.workshopBookingStatus.map((status) => (
						<option key={status.id} value={status.id}>
							{status.name}
						</option>
					))}
				</select>
			</FilterContainer>
			<StyledTableContainer>
				<Table hover className="mt-2">
					<thead>
						<tr>
							<th>Booking ID</th>
							<th>Client Name</th>
							<th>Service Booked</th>
							<th>Booking Status</th>
							<th>Drop Off Date</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{data.workshopBooking &&
							data.workshopBooking.map((b, i) => {
								return (
									<tr key={i}>
										<td>{b.id}</td>
										<td>{b.person.name}</td>
										<td>{b.workshopBookingService?.product.name}</td>
										<td>{b.status?.name}</td>
										<td>{b.date ? moment(b.date).format('DD/MM/YYYY') : 'Not Booked'}</td>
										<td>
											<Button color="primary" onClick={() => navigate(`/admin/workshop/` + b.id)}>
												View
											</Button>
										</td>
									</tr>
								);
							})}
					</tbody>
				</Table>
			</StyledTableContainer>
		</div>
	);
}
