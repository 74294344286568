import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Col, Row } from 'reactstrap';
import { useApp } from 'src/components/App/appContext';
import graphql from 'src/utils/graphql';

export default function BhpaDetailModal({ children }) {
	const { user, fetchUser } = useApp();
	const initialState = {
		bhpaNumber: '',
		bhpaExpiry: null
	};
	const [modal, setModal] = useState(false);
	const [state, setState] = useState(initialState);

	const handleChange = (e) => {
		if (e.target.name === 'bhpaExpiry' && e.target.value === '') e.target.value = null;
		const newState = {
			...state,
			[e.target.name]: e.target.value
		};
		setState(newState);
	};

	const toggle = () => {
		setState(initialState);
		setModal(!modal);
	};

	const complete = async () => {
		await graphql({
			query: `#graphql
				mutation ($input: PersonInput!) {
					savePerson(input: $input)
				}
			`,
			variables: {
				input: {
					...state,
					id: user.id
				}
			}
		});
		await fetchUser(); // Refresh me
		toggle();
	};

	return (
		<>
			<span onClick={toggle}>{children}</span>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Please complete BHPA details</ModalHeader>
				<ModalBody>
					Before your able to join the green dragons membership you need to input your BHPA details, if you are unsure
					of what this is please contact us at <a href="mailto:admin@greendragons.co.uk">admin@greendragons.co.uk</a>
					<br />
					<br />
					<FormGroup floating>
						<Input type="text" name="bhpaNumber" id="Number" value={state.bhpaNumber} onChange={handleChange} />
						<Label for="Number">BHPA Number (Required)</Label>
					</FormGroup>
					<FormGroup floating>
						<Input type="date" name="bhpaExpiry" id="Expiry" value={state.bhpaExpiry} onChange={handleChange} />
						<Label for="Expiry">BHPA Expiry</Label>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button disabled={!state.bhpaNumber} color="primary" onClick={complete}>
						Complete BHPA
					</Button>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
